import React, { useEffect } from 'react';

const CustomCursor: React.FC = () => {
  useEffect(() => {
    const cursorInner = document.querySelector('.inner-circle') as HTMLElement;
    const cursorOuter = document.querySelector('.outer-circle') as HTMLElement;
    const links = document.querySelectorAll('a, button, label');

    const moveCursor = (e: MouseEvent) => {
      const posX = e.clientX;
      const posY = e.clientY;

      cursorInner.style.left = `${posX}px`;
      cursorInner.style.top = `${posY}px`;

      cursorOuter.animate(
        {
          left: `${posX}px`,
          top: `${posY}px`,
        },
        { duration: 500, fill: 'forwards' }
      );
    };

    links.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        cursorInner.classList.add('hover');
        cursorOuter.classList.add('hover');
      });
      link.addEventListener('mouseleave', () => {
        cursorInner.classList.remove('hover');
        cursorOuter.classList.remove('hover');
      });
    });

    document.addEventListener('mousemove', moveCursor);

    return () => {
      document.removeEventListener('mousemove', moveCursor);
      links.forEach((link) => {
        link.removeEventListener('mouseenter', () => {});
        link.removeEventListener('mouseleave', () => {});
      });
    };
  }, []);

  return (
    <>
      <div className="outer-circle fixed top-0 left-0 -translate-x-1/2 -translate-y-1/2 pointer-events-none z-[9999] w-[35px] h-[35px] border-2 border-white rounded-full"></div>
      <div className="inner-circle fixed top-0 left-0 -translate-x-1/2 -translate-y-1/2 pointer-events-none z-[9999] w-[8px] h-[8px] bg-[#02ACFF] rounded-full"></div>
    </>
  );
};

export default CustomCursor;