import React, { useEffect, useState } from 'react';

const CounterBox: React.FC = () => {
  const [yearsExperience, setYearsExperience] = useState(0);
  const [commissionsCompleted, setCommissionsCompleted] = useState(0);
  const [positiveFeedback, setPositiveFeedback] = useState(0);

  useEffect(() => {
    const counters = [
      { target: 9, setter: setYearsExperience },
      { target: 126, setter: setCommissionsCompleted },
      { target: 99, setter: setPositiveFeedback },
    ];

    counters.forEach(({ target, setter }) => {
      let current = 0;
      const increment = target / 100; 
      const interval = setInterval(() => {
        current += increment;
        if (current >= target) {
          setter(target);
          clearInterval(interval);
        } else {
          setter(Math.ceil(current));
        }
      }, 20);
    });
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)] w-[97%] sm:h-[185px] h-96 rounded-3xl grid sm:grid-cols-3 sm:gap-0 grid-cols-1 grid-rows-1 grid-flow-row py-5 mb-10 max-w-[1140px] z-10">
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="max-w-full text-white text-6xl font-bold !text-center">
          {yearsExperience}+
        </div>
        <h2 className="text-white text-xl font-normal !text-center">
          Years Experience
        </h2>
      </div>

      <div className="flex flex-col justify-center items-center gap-5">
        <div className="max-w-full text-white text-6xl font-bold !text-center">
          {commissionsCompleted}+
        </div>
        <h2 className="text-white text-xl font-normal !text-center">
          Commissions Completed
        </h2>
      </div>

      <div className="flex flex-col justify-center items-center gap-5">
        <div className="max-w-full text-white text-6xl font-bold !text-center">
          {positiveFeedback}%
        </div>
        <h2 className="text-white text-xl font-normal !text-center">
          Positive Feedback
        </h2>
      </div>
    </div>
  );
};

export default CounterBox;
