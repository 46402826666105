import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import BgImage from "./images/bgimage.png";
import CanvasAnimation from "./components/CanvasAnimation";

const App: React.FC = () => {
  const [rate, setRate] = useState<string>("");
  const [monthlyRate, setMonthlyRate] = useState<string>("0");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    setRate(inputValue);
    const parsedValue = parseFloat(inputValue);

    if (!isNaN(parsedValue) && parsedValue > 0) {
      const calc = (parsedValue * 52) / 12;
      setMonthlyRate(calc.toFixed(2));
    } else {
      setMonthlyRate("0");
    }
  };

  return (
    <>
    <div className="h-full w-full">
      <Header />
      <CanvasAnimation />

      <div className="bg-gradient-to-b from-[#000000] to-[#02ACFF] z-50 min-h-screen flex items-center justify-center pb-32 sm:pt-72 pt-56">
        <div className="z-50 w-full max-w-md bg-[#02ACFF] rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500 p-8">
          <h1 className="text-4xl font-extrabold text-center text-white tracking-tight mb-6">
            Rate Calculator
          </h1>
          <p className="text-center text-blue-100 mb-6">
            Enter your weekly rate and discover your monthly equivalent.
          </p>
          <div className="mb-8">
            <label
              htmlFor="rate-input"
              className="block text-sm font-semibold text-white mb-2"
            >
              Weekly Rate ($)
            </label>
            <input
              id="rate-input"
              type="number"
              value={rate}
              onChange={handleInputChange}
              placeholder="e.g., 600"
              className="w-full px-5 py-3 rounded-lg bg-white text-gray-800 border border-gray-300 focus:ring-2 focus:ring-blue-400 outline-none"
            />
          </div>
          <div className="bg-white rounded-lg p-4">
            <p className="text-gray-700 text-center text-lg">
              Your Monthly Rate:
            </p>
            <p
              id="total"
              className="mt-3 text-center text-5xl font-bold text-[#003153]"
            >
              ${monthlyRate}
            </p>
          </div>
          <footer className="mt-8 text-center text-blue-100 text-sm">
            © 2025
            <a
              className="text-blue-200 font-extrabold text-lg hover:underline transition-all duration-300 hover:text-blue-400 hover:scale-105 glow-effect pr-3 pl-3"
              href="/#about"
            >
              1, 2, 3, Click!
            </a>
            All rights reserved.
          </footer>
        </div>
      </div>

      <Footer />
    </div>
    </>
  );
};

export default App;
