import React, { useEffect, useRef } from "react";

const CanvasAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let particles: Particle[] = [];
    let stars: Star[] = [];

    class Particle {
      x: number;
      y: number;
      size: number;
      color: string;
      speedX: number;
      speedY: number;

      constructor() {
        this.x = Math.random() * canvas!.width;
        this.y = canvas!.height + Math.random() * 100;
        this.size = 3;
        this.color = "#02ACFF";
        this.speedX = (Math.random() - 0.5) * 2;
        this.speedY = -Math.random() * 3 - 1;
      }

      draw(ctx: CanvasRenderingContext2D) {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.y + this.size < 0) {
          this.x = Math.random() * canvas!.width;
          this.y = canvas!.height + Math.random() * 100;
          this.speedX = (Math.random() - 0.5) * 2;
          this.speedY = -Math.random() * 3 - 1;
        }
      }
    }

    class Star {
      x: number;
      y: number;
      size: number;
      opacity: number;
      speedY: number;
      points: number;
      outerRadius: number;
      innerRadius: number;
      rotation: number;
      lifeSpan: number;
      age: number;

      constructor() {
        this.x = Math.random() * canvas!.width;
        this.y = Math.random() * canvas!.height;
        this.size = Math.random() * 8 + 3;
        this.opacity = Math.random() * 0.5 + 0.5;
        this.speedY = -Math.random() * 2 - 1;
        this.points = 5;
        this.outerRadius = this.size;
        this.innerRadius = this.size / 2;
        this.rotation = Math.random() * Math.PI;
        this.lifeSpan = Math.random() * 200 + 100;
        this.age = 0;
      }

      draw(ctx: CanvasRenderingContext2D) {
        ctx.save();
        ctx.globalAlpha = this.opacity;
        ctx.fillStyle = "#02ACFF";
        ctx.beginPath();
        const angleStep = Math.PI / this.points;
        for (let i = 0; i < 2 * this.points; i++) {
          const radius = i % 2 === 0 ? this.outerRadius : this.innerRadius;
          const angle = i * angleStep + this.rotation;
          const x = this.x + radius * Math.cos(angle);
          const y = this.y + radius * Math.sin(angle);
          ctx.lineTo(x, y);
        }
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }

      update() {
        this.y += this.speedY;
        this.size *= 0.97;
        this.opacity -= 0.02;
        this.age++;

        if (this.size < 1 || this.opacity <= 0 || this.age >= this.lifeSpan) {
          this.x = Math.random() * canvas!.width;
          this.y = Math.random() * canvas!.height;
          this.size = Math.random() * 8 + 3;
          this.opacity = Math.random() * 0.5 + 0.5;
          this.speedY = -Math.random() * 2 - 1;
          this.rotation = Math.random() * Math.PI;
          this.lifeSpan = Math.random() * 200 + 100;
          this.age = 0;
        }
      }
    }

    const createParticles = () => {
      for (let i = 0; i < 100; i++) {
        particles.push(new Particle());
      }
    };

    const createStars = () => {
      for (let i = 0; i < 50; i++) {
        stars.push(new Star());
      }
    };

    const animate = () => {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle) => {
        particle.update();
        particle.draw(ctx);
      });

      stars.forEach((star) => {
        star.update();
        star.draw(ctx);
      });

      requestAnimationFrame(animate);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);
    createParticles();
    createStars();
    animate();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} className="fixed top-0 left-0 w-full h-full z-[0] pointer-events-none" />;
};

export default CanvasAnimation;